.featured {
  position: relative;
}

.featured::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #7800A3;
  opacity: 16%;
  top: 0;
  left: 0;
  z-index: -1;
}

.subtitle {
  text-transform: uppercase;
  font-weight: bold;
  color: #0071B8;
  letter-spacing: .2em;
  font-size: .85em;
}

.featured-title {
  color: #161D27;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: -.4em;
  display: block;
}

.featured-desc {
  color: #161D27;
  margin-bottom: 3em;
  font-size: .85em;
  line-height: 1.8em;
  font-weight: 500;
}

.right {
  border-radius: 1em;
}

@media only screen and (min-width: 800px) {
  .featured {
    text-align: left;
  }

  .featured {
    display: grid;
    grid-template-columns: 40% auto;
  }

  .left {
    display: grid;
    place-content: center;
  }

  .right {
    margin-left: 2em;
    margin-top: 1em;
  }
}
