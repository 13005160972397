.hero-design {
  display: none;
}

@media only screen and (min-width: 1050px) {
  .hero-design {
    display: unset;
    margin-left: -50%;
    margin-top: 15%;
    width: 100%;
  }
}

@media only screen and (min-width: 1250px) {
  .hero .content {
    padding: 6em 8em 6em 10em;
  }
}

@media only screen and (min-width: 1550px) {
  .hero .content {
    padding: 9em 20em 6em 20em;
  }
}
