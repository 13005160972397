.scroll {
  width: 30px;
  margin-top: 2em;

  .circle {
    animation: circleAnim 1s infinite alternate-reverse;
  }

  @keyframes circleAnim {
    from {
      transform: translate(259px, 552px);
      z-index: 5;
    }
    to {
      transform: translate(259px, 592px);
    }
  }
}
