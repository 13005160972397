.header {
  .social-right {
    display: none;
  }

  @media only screen and (min-width: 1050px) {
    header {
      background: $green;
      padding: 2em 4em 2em 4em !important;
    }

    .social-right {
      display: unset;
      padding: 2em 4em 0 0;
    }

    .social-right ul {
      display: flex;
      justify-content: flex-end;
      padding-top: .125em;

      img {
        height: 40px;
        width: 40px;
      }

      li {
        margin-left: 2em;
      }
    }
  }

  @media only screen and (min-width: 1250px) {
    header {
      padding: 2em 4em 2em 10em !important;
    }
  }

  @media only screen and (min-width: 1550px) {
    header {
      padding: 2em 4em 2em 20em !important;
    }
  }
}
