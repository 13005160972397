@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
  height: 100vh;
  margin: 0;
  font-family: 'Poppins', arial, sans-serif;
  background: #E5E5E5;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

img {
  width: 100%;
}
