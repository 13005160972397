// Colors

$green: #46B030;
$charcoal: #161D27;
$orange: #F46036;
$platinum: #E5E5E5;
$purple: #7800A3;

// Font sizes

$medium: 1.1em;
$large: 1.3em;
